<template>
  <div></div>
</template>

<script>
export default{
  mounted(){
    if(this.$route.query.link){
      this.$router.push({
        path: '/tan-invite',
        query:{
          link: this.$route.query.link
        }
      })
    }
  }
}
</script>