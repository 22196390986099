<template>
  <div class="container">
    <div class="logo-line">
      <img src="@/assets/images/Tan/identity/logo.png" class="logo"/>
      <p class="p-0">企业科技创新的核心基础设施</p>
    </div>
    <div class="window" v-if="!success">
      <p class="p-0">{{inviteInfo.name}}邀请你加入探角数字科创中心</p>
      <p class="p-1">由{{inviteInfo.companyName}}创建</p>
      <div class="form-item">
        <div class="title"><span>*</span>姓名：</div>
        <el-input v-model="form.name" class="input" placeholder="请输入姓名" maxlength="20"/>
      </div>
      <div class="form-item">
        <div class="title"><span>*</span>手机号：</div>
        <el-input v-model="form.phone" class="input" placeholder="请输入手机号" maxlength="11" @input="handleNumber()"/>
      </div>
      <div class="form-item">
        <div class="title"><span>*</span>验证码：</div>
        <el-input v-model="form.code" class="input" placeholder="请输入验证码" maxlength="6" @input="handleCode()">
          <template #append>
            <button class="code-button" :class="{'disabled': !showCode}" @click="getPhoneCode()">
              {{ showCode ? '获取验证码' : count + '秒' }}</button>
          </template>
        </el-input>
      </div>
      <div class="form-item">
        <div class="title"><span>*</span>职务：</div>
        <el-select v-model="form.position" style="width: 300px;" placeholder="请选择" clearable>
          <el-option
            v-for="(item,index) in positionList"
            :key="index"
            :label="item"
            :value="item"/>
        </el-select>
      </div>
      <button class="next-button" :class="{'failed': linkFail}" @click="submit" v-loading="loading">提交申请</button>
    </div>
    <div class="window-success" v-else>
      <div class="content_2">
        <img src="@/assets/images/Tan/identity/apply.png" class="apply"/>
        <p class="p-1">申请已提交！请等待主管理审核</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import { getPhoneCode } from "@/api/user";
import { joinTanByLink } from "@/api/Tan/company";
import { getTanInviteInfo } from "@/api/Tan/member";
import { getSelectFatherSonByType } from "@/api/config";
import { login } from '@/api/user';
import { requestVersion } from "@/utils/consts";

export default{
  data(){
    return{
      linkFail: false,
      loading: false,
      success: false,
      inviteInfo: {
        name: '-',
        companyName: '-',
      },
      positionList: [],
      form:{
        name: '',
        phone: '',
        code: '',
        position: '',
      },
      link: '',
      count: 60,
      showCode: true, // 是否显示倒计时
      timer: null,
    }
  },
  mounted(){
    this.getSelection()
    this.getQuery()
  },
  methods:{
    getSelection(){
      getSelectFatherSonByType(10034).then(res=>{
        this.positionList = res.data.map(item=>item.val)
      })
    },
    submit(){
      if(this.linkFail){
        ElMessage.warning('链接错误，请检查链接是否正确！')
        return
      }
      if(!this.form.name){
        ElMessage.warning('请输入姓名')
        return
      }
      else if(!this.form.phone){
        ElMessage.warning('请输入手机号')
        return
      }
      else if(!this.form.code){
        ElMessage.warning('请输入验证码')
        return
      }
      else if(!this.form.position){
        ElMessage.warning('请选择职务')
        return
      }
      this.loading = true
      this.loginInvite()
    },
    submitForm(res){
      // 提交申请
      joinTanByLink({
        "duties": this.form.position,
        "inviteTag": this.link,
        "memberId": res.memberInfo.id,
        "memberName": this.form.name,
        "tempToken": res.accessToken,
      }).then(()=>{
        this.success = true
        this.resetForm()
      }).catch(err=>{
        console.log(err);
      }).finally(()=>{
        this.loading = false
      })
    },
    loginInvite(){
      const params = {
        machineCode: '',
        version: requestVersion,
        canal: 1,
        type: 0,
        code: this.form.code,
        phone: this.form.phone,
      }
      login(params).then((res)=>{
        this.submitForm(res.data)
      }).catch(()=>{
        this.loading = false
      })
    },
    handleCode(){
      let pattern = new RegExp("^[0-9]|-$")
      this.form.code = Array.from(this.form.code).filter(v => pattern.test(v)).join("")
    },
    handleNumber(){  // 数字处理
      let pattern = new RegExp("^[0-9]|-$")
      this.form.phone = Array.from(this.form.phone).filter(v => pattern.test(v)).join("")
    },
    resetForm(){
      this.form = {
        name: '',
        phone: '',
        code: '',
        position: '',
      }
      if(this.timer){
        this.showCode = true;
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    getQuery(){
      let li = ''
      if(window.location.href.indexOf('link=') !== -1){
        li = window.location.href.slice(window.location.href.indexOf('link=') + 5)
      }
      else {
        li = this.$route.query.link
      }
      this.link = li ? decodeURIComponent(li) : ''
      this.getInviteInfo()
    },
    getInviteInfo(){
      // 获取邀请信息
      if(!this.link){
        ElMessage.error('链接错误')
        return
      }
      getTanInviteInfo(this.link).then(res=>{
        this.inviteInfo.name = res.data.name
        this.inviteInfo.companyName = res.data.tjCompanyName
      }).catch(()=>{
        this.linkFail = true
      })
    },
    getPhoneCode() {
      const TIME_COUNT = 60;
      const reg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (reg.test(this.form.phone) == false) {
        ElMessage.warning("您输入的手机号码格式不正确，请重新输入");
        return;
      }
      getPhoneCode({ phone: this.form.phone })
        .then(() => {
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.showCode = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.showCode = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
          ElMessage.success("发送成功");
        })
        .catch((err) => {
          ElMessage.error({ message: err.message });
        });
    },
  }
}
</script>
<style lang="scss" scoped>
.container{
  width: calc(100% + 17px);
  min-height: 100vh;
  background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/07/14/1723598508320586');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow-x: hidden;
  box-sizing: border-box;
  padding-top: 86px;
  padding-bottom: 86px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  .logo-line{
    width: 881px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 94px;
    .logo{
      width: 93px;
      height: 51px;
      margin-right: 15px;
    }
    .p-0{
      position: relative;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #333333;
      padding-left: 15px;
      padding-bottom: 8px;
      &::before{
        content: '';
        width: 1px;
        height: 14px;
        background: #333333;
        position: absolute;
        left: 0;
        bottom: 10px;
      }
    }
  }
  .window{
    width: 881px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 10px rgba(160,160,160,0.03);
    border-radius: 10px;
    background-image: url('../../assets/images/Tan/invite/invite.png');
    background-size: 100% 153px;
    background-repeat: no-repeat;
    background-position: top left;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 70px;
    .p-0{
      width: 767px;
      padding-top: 50px;
      padding-bottom: 7px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 24px;
      color: #333333;
      line-height: 33px;
    }
    .p-1{
      width: 767px;
      padding-bottom: 7px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #666666;
      line-height: 22px;
      padding-bottom: 80px;
    }
    .form-item{
      display: flex;
      align-items: center;
      padding: 14px 0;
      .title{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 16px;
        width: 70px;
        text-align: right;
        span{
          color: #ED0900;
        }
      }
      .input{
        width: 300px;
        :deep(.el-input-group__append){
          background-color: #ffffff;
        }
      }
      .code-button{
        width: 70px;
        height: 100%;
        background-color: #ffffff;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: $--color-theme;
        border: none;
        cursor: pointer;
      }
      .disabled{
        cursor: not-allowed !important;
        color: #2282ff6f !important;
      }
    }
    .next-button{
      margin-top: 30px;
      width: 172px;
      height: 32px;
      background: $--color-theme;
      border-radius: 3px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
      border: none;
      cursor: pointer;
    }
    .failed{
      cursor: not-allowed !important;
      background-color: #999999 !important;
    }
  }
  .window-success{
    width: 881px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 10px rgba(160,160,160,0.03);
    border-radius: 10px;
    background-image: url('../../assets/images/Tan/invite/invite.png');
    background-size: 100% 153px;
    background-repeat: no-repeat;
    background-position: top left;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .content_2{
    height: 417px;
    background: linear-gradient( 180deg, #CEDFFF 0%, #FEFFFF 21%, #FFFFFF 100%);
    box-shadow: 0px 2px 8px 8px rgba(3,11,52,0.01);
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .apply{
      width: 165px;
      height: 111px;
      margin-bottom: 41px;
    }
    .p-1{
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 20px;
      color: #333333;
      line-height: 28px;
      text-align: center;
    }
  }
}
</style>